import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { Button, TextField } from '@mui/material';
import Navbar from "./Navbar.js"


export function PasswordReset({history}) {
    const [email_entered, set_email_entered] = useState("")


    const handle_reset_password = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        try {
            sendPasswordResetEmail(auth, email_entered)
            set_page_content(
                [
                    <p>
                        Please check your email to reset your password. (Make sure to check your spam folder too!)
                    </p>
                ]
            )
            console.log("Sent password-reset email! ")
        } catch (error) {
            console.log(error);
        }
    }

    const text_field_styling = {
        marginTop: "20px"
    }


    const [page_content, set_page_content] = useState([])



    return (
        <div>
            <Navbar navbar_type={"login_page"}/>

            <form onSubmit={handle_reset_password}>
                <TextField label="Email"
                           value={email_entered}
                           onChange={(event) => {
                               console.log(event.target.value)
                               set_email_entered(event.target.value)
                           }}
                           variant="outlined"
                           type="email"
                           style={text_field_styling} />

                <Button type="submit"
                        style={{display: "block", margin: "auto"}}>
                    Reset Password
                </Button>
            </form>

            {page_content}
        </div>

    )
}


export default PasswordReset;
