import React, { useEffect, useRef } from 'react';
import { useInfiniteHits } from 'react-instantsearch-hooks-web';

export function InfiniteHits({ hitComponent: HitComponent, ...props }) {
    const { hits, isLastPage, showMore } = useInfiniteHits(props);
    const sentinelRef = useRef(null);

    useEffect(() => {
        if (sentinelRef.current !== null) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !isLastPage) {
                        showMore();
                    }
                });
            });

            observer.observe(sentinelRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [isLastPage, showMore]);

    const hits_list_styling = {
        listStyle: "none"
    }

    return (
        <div className="ais-InfiniteHits">
            <ul className="ais-InfiniteHits-list"
                style={hits_list_styling}>
                {hits.map((hit) => (
                    <li key={hit.objectID} className="ais-InfiniteHits-item">
                        <HitComponent hit={hit} />
                        
                    </li>
                ))}

                <li
                    className="ais-InfiniteHits-sentinel"
                    ref={sentinelRef}
                    aria-hidden="true"
                />
            </ul>
        </div>
    );
}
