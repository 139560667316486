import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom';


export function Navbar(props) {
    const navbar_type = props.navbar_type

    var navbar_content;
    const navbar_color_styling = { bgcolor: "black" }

    if (navbar_type === "homepage"
        || navbar_type === "drafts_page") {
        navbar_content =
            <>
            <MediaQuery query='(min-device-width: 1000px)'>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={navbar_color_styling} >
                        <Toolbar>
                            <Button onClick={() => {navigate("/create_trip")}}
                                    startIcon={<AddCircleIcon />}
                                    color="inherit"
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}>
                                Create Trip
                            </Button>

                            <Typography variant="h2"
                                        component="div"
                                        onClick={() => {navigate("/")}}
                                        sx={{ flexGrow: 1, fontWeight: 'bold', cursor: "pointer" }}>
                                tripsharer
                            </Typography>

                            <Button onClick={() => {navigate("/contact")}}
                                    color="inherit"
                                    sx={{ marginRight: "20px" }}>
                                Contact
                            </Button>

                            <Button onClick={() => {navigate("/privacy_policy")}}
                                    color="inherit"
                                    sx={{ marginRight: "20px" }}>
                                Privacy-Policy
                            </Button>

                            <Button onClick={() => {navigate("/profile")}}
                                    color="inherit"
                                    endIcon={<AccountBoxIcon />}>
                                Profile
                            </Button>
                        </Toolbar>
                    </AppBar>
                </Box>
            </MediaQuery>

            <MediaQuery query='(max-device-width: 1000px)'>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={navbar_color_styling} >
                        <Toolbar>
                            <Button onClick={() => {navigate("/create_trip")}}
                                    startIcon={<AddCircleIcon />}
                                    color="inherit"
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}>
                                Create Trip
                            </Button>

                            <Typography variant="h5"
                                        component="div"
                                        onClick={() => {navigate("/")}}
                                        sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                                tripsharer
                            </Typography>


                            <MenuList className="main-nav">
                                <MenuItem>
                                    <NavLink to="/contact" style={{color: "white"}}>Contact</NavLink>
                                </MenuItem>
                                <MenuItem>
                                    <NavLink to="/privacy_policy" style={{color: "white"}}>Privacy Policy</NavLink>
                                </MenuItem>
                                <MenuItem>
                                    <NavLink to="/profile" style={{color: "white"}}>Account</NavLink>
                                </MenuItem>
                            </MenuList>
                        </Toolbar>
                    </AppBar>
                </Box>
            </MediaQuery>
            </>



    } else if (navbar_type === "login_page"
               || navbar_type === "signup_page"
               || navbar_type === "password_reset_page"
               || navbar_type === "trip_full_display_page"
               || navbar_type === "create_trip"
               || navbar_type === "edit_trip"
               || navbar_type === "privacy_policy"
               || navbar_type === "contact"
               || navbar_type === "edit_profile"
               || navbar_type === "liked_trips") {
        navbar_content =
        <>
        <MediaQuery query='(min-device-width: 1000px)'>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={navbar_color_styling} >
                    <Toolbar>
                        <Button onClick={() => {navigate("/")}}
                                startIcon={<ArrowBackIcon />}
                                color="inherit"
                                size="large"
                                edge="start"
                                aria-label="menu"
                                sx={{ mr: 2 }}>
                            Home
                        </Button>

                        <Typography variant="h2"
                                    component="div"
                                    onClick={() => {navigate("/")}}
                                    sx={{ flexGrow: 1, fontWeight: 'bold', cursor: "pointer" }}>
                            tripsharer
                        </Typography>

                        <Button onClick={() => {navigate("/contact")}}
                                color="inherit"
                                sx={{ marginRight: "20px" }}>
                            Contact
                        </Button>

                        <Button onClick={() => {navigate("/privacy_policy")}}
                                color="inherit"
                                sx={{ marginRight: "20px" }}>
                            Privacy-Policy
                        </Button>

                        <Button onClick={() => {navigate("/profile")}}
                                color="inherit"
                                endIcon={<AccountBoxIcon />}>
                            Profile
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>
        </MediaQuery>

        <MediaQuery query='(max-device-width: 1000px)'>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={navbar_color_styling} >
                    <Toolbar>
                        <Button onClick={() => {navigate("/")}}
                                startIcon={<ArrowBackIcon />}
                                color="inherit"
                                size="large"
                                edge="start"
                                aria-label="menu"
                                sx={{ mr: 2 }}>
                            Home
                        </Button>

                        <Typography variant="h5"
                                    component="div"
                                    onClick={() => {navigate("/")}}
                                    sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                            tripsharer
                        </Typography>


                        <MenuList className="main-nav">
                            <MenuItem>
                                <NavLink to="/contact" style={{color: "white"}}>Contact</NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink to="/privacy_policy" style={{color: "white"}}>Privacy Policy</NavLink>
                            </MenuItem>
                            <MenuItem>
                                <NavLink to="/profile" style={{color: "white"}}>Account</NavLink>
                            </MenuItem>
                        </MenuList>
                    </Toolbar>
                </AppBar>
            </Box>
        </MediaQuery>
        </>

    } else if (navbar_type === "profile_page") {
        navbar_content =

            <>
            <MediaQuery query='(min-device-width: 1000px)'>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={navbar_color_styling} >
                        <Toolbar>
                            <Button onClick={() => {navigate("/")}}
                                    startIcon={<ArrowBackIcon />}
                                    color="inherit"
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}>
                                Home
                            </Button>

                            <Typography variant="h2"
                                        component="div"
                                        onClick={() => {navigate("/")}}
                                        sx={{ flexGrow: 1, fontWeight: 'bold', cursor: "pointer" }}>
                                tripsharer
                            </Typography>

                            <Button onClick={() => {navigate("/contact")}}
                                    color="inherit"
                                    sx={{ marginRight: "20px" }}>
                                Contact
                            </Button>

                            <Button onClick={() => {navigate("/privacy_policy")}}
                                    color="inherit"
                                    sx={{ marginRight: "20px" }}>
                                Privacy-Policy
                            </Button>

                            <Button onClick={() => {navigate("/signout")}}
                                    color="inherit">
                                Sign Out
                            </Button>
                        </Toolbar>
                    </AppBar>
                </Box>
            </MediaQuery>

            <MediaQuery query='(max-device-width: 1000px)'>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={navbar_color_styling} >
                        <Toolbar>
                            <Button onClick={() => {navigate("/")}}
                                    startIcon={<ArrowBackIcon />}
                                    color="inherit"
                                    size="large"
                                    edge="start"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}>
                                Home
                            </Button>

                            <Typography variant="h5"
                                        component="div"
                                        onClick={() => {navigate("/")}}
                                        sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                                tripsharer
                            </Typography>


                            <MenuList className="main-nav">
                                <MenuItem>
                                    <NavLink to="/contact" style={{color: "white"}}>Contact</NavLink>
                                </MenuItem>
                                <MenuItem>
                                    <NavLink to="/privacy_policy" style={{color: "white"}}>Privacy Policy</NavLink>
                                </MenuItem>
                                <MenuItem>
                                    <NavLink to="/signout" style={{color: "white"}}>Sign Out</NavLink>
                                </MenuItem>
                            </MenuList>
                        </Toolbar>
                    </AppBar>
                </Box>
            </MediaQuery>
            </>

    }


    const navigate = useNavigate()




    return (
        navbar_content
    )

}



export default Navbar;
