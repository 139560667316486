import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './TripEntry.css';

// props attributes
    // trip_ID (String)
    // trip_owner (String)
    // trip_name (string)
    // trip_photos (list)

export function TripEntry(props) {
    const edit_enabled = props.edit_enabled
    const [images_URL_list, set_images_URL_list] = useState([])
    const [images_content, set_images_content] = useState([])
    const [trip_JSON, set_trip_JSON] = useState({
        trip_name: "Loading..."
    })


    const trip_ID = props.trip_ID
    const trip_name = props.trip_name
    const trip_URL = "/trip_full_display/" + trip_name + "/" + trip_ID
    const trip_entry_styling = {
        margin: "10px 10% 20px 10%",
        border: "1px ",
        borderRadius: "20px",
        overflow: "hidden",
        cursor: "pointer"
    };
    const trip_name_styling = {
        display:"inline",
        float: "left",
        width: "40%",
        textAlign: "left"
    }
    const trip_image_styling = {
        borderRadius: "10px",
        width: "30%",
        marginRight: "20px"
    }
    const trip_images_preview_div_styling = {
        float: "left",
        width: "60%",
        marginTop: "20px",
        marginBottom: "20px"
    }


    const generate_images_content = async () => {
        if (images_URL_list.length < 3) {
            const new_images_content = []
            images_URL_list.forEach((image_URL) => {
                new_images_content.push(
                    <img src={image_URL}
                        style={trip_image_styling}
                    />,
                )
            })
            set_images_content(new_images_content)
        } else {
            set_images_content(
                [
                    <img key="image_01"
                        src={images_URL_list[0]}
                        style={trip_image_styling}
                    />,
                    <img key="image_02"
                        src={images_URL_list[1]}
                        style={trip_image_styling}
                    />,
                    <img key="image_03"
                        src={images_URL_list[2]}
                        style={trip_image_styling}
                    />,
                ]
            )
        }
    }
    useEffect(() => {
        generate_images_content()
    }, [images_URL_list])

    const get_images_list = async () => {
        const new_images_list = []
        if (trip_JSON["trip_name"] !== "Loading...") {
            trip_JSON["activities"].forEach((activity) => {
                activity["activity_images_download_URLs_list"].forEach((image_download_URL) => {
                    new_images_list.push(image_download_URL)
                })
            })
            set_images_URL_list(new_images_list)
        }

    }
    useEffect(() => {
        get_images_list()
    }, [trip_JSON])


    const get_trip_JSON = async () => {
        const docRef = doc(db, "trips", trip_ID)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await set_trip_JSON(docSnap.data()["body"])
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        const images_download_URL_list = []

    }

    useEffect(() => {
        get_trip_JSON()
    }, [])




    return (
        <Link key={trip_URL+"_key"}
              to={trip_URL}
              target="_blank"
              onClick={() => {
                  localStorage.setItem("current_viewing_trip_ID", trip_ID)
                  localStorage.setItem("current_viewing_trip_edit_enabled", edit_enabled)
              }}
              // state={{
              //      trip_ID: trip_ID,
              //      edit_enabled: edit_enabled
              //  }}
              style={{ color: "#000" }} >
            <div className="trip_entry_view"
                 style={trip_entry_styling}>

                <h2 className="trip_name_text"
                   style={trip_name_styling}>
                    {trip_JSON["trip_name"]}
                </h2>

                <div className="trip_images_preview_div" style={trip_images_preview_div_styling}>
                    {images_content}
                </div>

            </div>
        </Link>
    )

}


export default TripEntry;
