import { TextInput } from "react-native";
import { useState, useEffect } from "react";
import Textarea from 'react-expanding-textarea'
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage, db } from "../firebase";
import { v4 } from "uuid";




export function EditTripActivity(props) {
    const activity_name_styling = {
        fontSize: "20px",
        width: "500px",
        height: "20px",
        marginLeft: "160px",
        marginBottom: "5px",
        borderBottomColor: "black",
        borderBottomWidth: "0px",
        paddingBottom: "20px",
        paddingTop: "20px"
    }

    const activity_details_styling = {
        color: "black",
        fontSize: "20px",
        fontFamily: "Arial, Helvetica, sans-serif",
        width: "500px",
        height: "100px",
        border: "0px solid black",
        marginLeft: "160px",
        borderBottomColor: "black",
        borderBottomWidth: "0px"
    }
    const image_styling = {
        height: "100px"
    }
    const image_upload_button_styling = {
        height: "100px"
    }


    const trip_JSON = props.trip_JSON
    const set_trip_JSON = props.set_trip_JSON
    const activity_index = props.activity_index


    const [activity_name, set_activity_name] = useState(trip_JSON["activities"][activity_index]["activity_name"])
    const [activity_details, set_activity_details] = useState(trip_JSON["activities"][activity_index]["activity_details"])
    const [images, setImages] = useState(trip_JSON["activities"][activity_index]["activity_images_download_URLs_list"])
    const [image_units, set_image_units] = useState([])

    const activity_name_onChange =  (event) => {
        set_activity_name(event.target.value)
        set_trip_JSON(prev_state => {
            prev_state["activities"][activity_index]["activity_name"] = event.target.value
            return prev_state
        })
    }

    const activity_details_onChange =  (event) => {
        set_activity_details(event.target.value)
        set_trip_JSON(prev_state => {
            prev_state["activities"][activity_index]["activity_details"] = event.target.value
            return prev_state
        })
    }

    async function uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            set_trip_JSON(prev_state => {
                prev_state["activities"][activity_index]["activity_images_download_URLs_list"].push(file)
                console.log(prev_state)
                return prev_state
            })
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            set_trip_JSON(prev_state => {
                prev_state["activities"][activity_index]["activity_images_download_URLs_list"].push(downloadURL)
                console.log(prev_state)
                return prev_state
            })
        })
    }


    const remove_image = async (i) => {
        console.log("REMOVING IMAGE NUMBER: ", i)
        await setImages(prev_state => {
            prev_state.splice(i, 1)
            console.log(prev_state)
            return prev_state
        })

        // await set_trip_JSON(prev_state => {
        //     console.log("prev", prev_state["activity_groups"][activity_group_index]["activities"][activity_index]["activity_images_download_URLs_list"])
        //     prev_state["activity_groups"][activity_group_index]["activities"][activity_index]["activity_images_download_URLs_list"].splice(i, 1)
        //     console.log("bruh", prev_state["activity_groups"][activity_group_index]["activities"][activity_index]["activity_images_download_URLs_list"])
        //     return prev_state
        // })

        await update_image_units()
    }
    const add_new_image = async (file) => {
        const lst = trip_JSON["activities"][activity_index]["activity_images_download_URLs_list"]
        await uploadImage(file, lst)

        await update_image_units()
    }

    const update_image_units = async () => {
        const lst = []
        console.log("UPDATE IMAGE UNITS")
        console.log(images)
        console.log(trip_JSON["activities"][activity_index]["activity_images_download_URLs_list"])
        images.forEach((element, i) => {
            var src = images[i]
            if ((typeof src !== 'string')) {
                src = URL.createObjectURL(images[i])
            }
            lst.push(
            <div className="image_div" key={i} >
                <img
                    src={src}
                    className="uploaded_image"
                    style={image_styling}
                    onClick={() => {remove_image(i)}} />
            </div> )
        })
        await set_image_units(lst)
    }

    useEffect(() => {
        update_image_units()
    }, [])


    return (
        <>
            <TextInput
                placeholder="Activity Name"
                placeholderTextColor="gray"
                style={activity_name_styling}
                value={activity_name}
                onChange={(event) => {
                    activity_name_onChange(event)
                }}
                key={"activity_name" + activity_index}
            />
            <br></br>

            <Textarea
                placeholder="Details"
                multiline="true"
                style={activity_details_styling}
                value={activity_details}
                onChange={(event) => {
                    activity_details_onChange(event)
                }}
                key={"activity_details" + activity_index}
            />

            <br></br>

            Upload Images
            <div className="image_upload">
                {image_units}

                <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange = {(event) => {
                        const [file] = event.target.files
                        // setImages(prev_state =>  [
                        //     ...prev_state,
                        //     file
                        // ])
                        add_new_image(file)
                    }}
                    style={image_upload_button_styling}
                />
            </div>


        </>
    )

}


export default EditTripActivity;
