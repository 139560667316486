import React, { useEffect, useState } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import { storage, db } from "../firebase";
import { v4 } from "uuid";
import { getAuth } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { TextInput } from "react-native";
import Activity from "./Activity.js"
import { Button,
         ButtonGroup,
         CircularProgress,
         Alert,
         Modal,
         Typography,
         Box,
         Badge,
         ToggleButton,
         ToggleButtonGroup } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Navbar from "./Navbar.js"
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from 'react-responsive';




export function CreateTrip(props) {
    const get_started_popup_style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
    const turn_screen_landscape_popup_style = {
        width: "200px",
        padding: "20px",
        marginTop: "30%",
        marginLeft: "10%",
        bgcolor: 'background.paper',
        boxShadow: 24,
    }
    const create_trip_title_styling = {
        textAlign: "left",
        fontSize: "50px",
        marginTop: "0px",
        marginBottom: "15px",
        marginLeft: "10%",
        marginRight: "10%",
        borderBottom: "5px solid black",
    }
    const trip_name_styling = {
        fontSize: "50px",
        borderBottomColor: "black",
        borderBottomWidth: "0px",
        marginBottom: "25px",
        display: "block",
        margin: "auto"
    }
    const save_as_draft_button_styling = {
        display: "block",
        margin: "auto",
        marginTop: "100px",
    }
    const submit_button_styling = {
        display: "block",
        margin: "auto",
        marginTop: "20px",
        marginBottom: "100px",
    }
    const add_remove_activity_group_button_group_styling = {
        marginTop: "100px"
    }
    const delete_button_styling = {
        display: "block",
        marginLeft: "66%",
    }

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const navigate = useNavigate()
    const auth = getAuth()
    const currentUser = auth.currentUser
    const location = useLocation()
    const [delete_button, set_delete_button] = useState([])
    const [get_started_popup_open, set_get_started_popup_open] = useState(false)
    const [turn_screen_landscape_popup_open, set_turn_screen_landscape_popup_open] = useState(false)
    const [public_private_state, set_public_private_state] = useState("public")
    const [submit_buttons_disabled, set_submit_buttons_disabled] = useState(false)

    var trip_ID = ""
    var delete_enabled = false

    // if trip_ID exists, populate trip name and activity groups
    // if trip_ID does not exist, do nothing (as before the edit feature was implemented)
    if ((location.state) && (location.state.trip_ID !== undefined)) {
        trip_ID = location.state.trip_ID
    }
    if ((location.state) && (location.state.delete_enabled !== undefined)) {
        delete_enabled = location.state.delete_enabled
    }
    if (sessionStorage.getItem("current_viewing_trip_ID") !== null) {
        trip_ID = sessionStorage.getItem("current_viewing_trip_ID")
    }
    if (sessionStorage.getItem("delete_enabled") !== null) {
        delete_enabled = sessionStorage.getItem("delete_enabled")
    }

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);




    const [trip_JSON, set_trip_JSON] = useState({})
    const [trip_name, set_trip_name] = useState("")

    const [activities, set_activities] = useState([])

    var initial_activity_name_list_state = []
    var initial_activity_details_list_state = []
    var initial_activity_images_list_state = []
    // const activities_initial_state_list = trip_JSON["activities"]
    // activities_initial_state_list.forEach((activity) => {
    //     initial_activity_name_list_state.push(activity["activity_name"])
    //     initial_activity_details_list_state.push(activity["activity_details"])
    //     initial_activity_images_list_state.push(activity["activity_images_download_URLs_list"])
    // })

    const [activity_name_list_state, set_activity_name_list_state] = useState(initial_activity_name_list_state)
    const [activity_details_list_state, set_activity_details_list_state] = useState(initial_activity_details_list_state)
    const [activity_images_list_state, set_activity_images_list_state] = useState(initial_activity_images_list_state)

    // const add_activity_group = async (activity_group_initial_state_list) => {
    //     await set_activity_groups(previous_activity_groups => [...previous_activity_groups,
    //                         <ActivityGroup key={previous_activity_groups.length}
    //                                        index={previous_activity_groups.length}
    //                                        activity_group_content_list={activity_group_content_list}
    //                                        set_activity_group_content_list={set_activity_group_content_list}
    //                                        activity_group_initial_state_list={activity_group_initial_state_list} />])
    // }

    const add_activity = async (activity_initial_state) => {
        const activity_name_component_name = "activity_name_" + activities.length
        await set_activities(previous_activities => [...previous_activities,
                            <Activity key={activities.length}
                                      index={activities.length}
                                      activity_name_component_name={activity_name_component_name}
                                      activity_name_list_state={activity_name_list_state}
                                      set_activity_name_list_state={set_activity_name_list_state}
                                      activity_details_list_state={activity_details_list_state}
                                      set_activity_details_list_state={set_activity_details_list_state}
                                      activity_images_list_state={activity_images_list_state}
                                      set_activity_images_list_state={set_activity_images_list_state}
                                      activity_initial_state = {activity_initial_state} />])
    }


    const get_trip_JSON = async () => {
        const docRef = doc(db, "trips", trip_ID)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await set_trip_JSON(docSnap.data()["body"])
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    useEffect(() => {
        if (isMobile) {
            set_turn_screen_landscape_popup_open(true)
        }
    }, [])

    useEffect(() => {
        if ((trip_ID !== "") && (trip_ID.length > 0)) {
            get_trip_JSON()
        }
    }, [trip_ID])


    useEffect( () => {
        if (Object.keys(trip_JSON).length > 0) {
            set_trip_name(trip_JSON["trip_name"])
            trip_JSON["activities"].forEach((activity) => {
                const activity_name = activity["activity_name"]
                const activity_details = activity["activity_details"]
                const activity_images = activity["activity_images_download_URLs_list"]

                const activity_initial_state_list = [activity_name, activity_details, activity_images]
                add_activity(activity_initial_state_list)
            })
        }

    }, [trip_JSON])


    const delete_most_recent_activity = () => {
        activities.pop()
        set_activities([...activities])
    }


    async function add_trip_document_ID_to_user_object(trip_document_ID) {
        const auth = getAuth()
        const currentUserID = auth.currentUser.uid

        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

        const querySnapshot = await getDocs(q);
        if ((trip_ID !== "") && (trip_ID.length > 0)) {return} // existing trip
        querySnapshot.forEach((document) => {
            // doc.data() is never undefined for query doc snapshots
            const new_trip_IDs_list = document.data()["user_trip_IDs"]
            new_trip_IDs_list.push(trip_document_ID)

            const docRef = doc(db, "users", document.id)
            setDoc(docRef, { user_trip_IDs: new_trip_IDs_list }, { merge: true })
        });
    }

    async function upload_trip_to_firestore(trip_JSON, is_draft) {
        console.log("trip JSON to be submitted: ", trip_JSON)

        if ((trip_ID !== "") && (trip_ID.length > 0)) {
            const docRef = doc(db, 'trips', trip_ID);
            setDoc(docRef, {
                body: trip_JSON,
                is_draft: is_draft
            }, { merge: true });
        } else {
            const auth = getAuth()
            const currentUserID = auth.currentUser.uid

            const docRef = await addDoc(collection(db, "trips"), {
                body: trip_JSON,
                creator: currentUserID,
                liked_by: [],
                created_at: Date.now(),
                is_draft: is_draft
            });
            await add_trip_document_ID_to_user_object(docRef.id)
        }
    }

    async function uploadImage(file, lst) {
        if (typeof file === 'string' && file.includes("firebasestorage")) {
            lst.push(file)
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            lst.push(downloadURL)
        })
    }

    async function get_download_URLs() {
        // [activity group][activity name/details/images list][item index]
        //                             0     1       2
        const images_master_list = []

        for (var i = 0; i < activity_images_list_state.length; i++) {
            const single_activity_image_list = []
            for (var j = 0; j < activity_images_list_state[i].length; j++) {
                const image = activity_images_list_state[i][j]
                await uploadImage(image, single_activity_image_list)
            }
            images_master_list.push(single_activity_image_list)
        }

        return images_master_list
    }

    const submit_trip = async (images_master_list, is_draft) => {
        const trip_JSON = {}
        trip_JSON["trip_name"] = trip_name
        trip_JSON["activities"] = []

        for (var i = 0; i < activity_name_list_state.length; i++) {
            const activity_JSON = {}
            activity_JSON["activity_name"] = activity_name_list_state[i]
            activity_JSON["activity_details"] = activity_details_list_state[i]
            activity_JSON["activity_images_download_URLs_list"] = images_master_list[i]
            trip_JSON["activities"].push(activity_JSON)
        }

        if (trip_JSON["trip_name"].length === 0) {
            // synchronous
            alert("Please enter a title for your trip. ")
            await set_submit_buttons_disabled(false)
            return
        }

        if (is_draft === true) {
            await set_save_as_draft_button_content([
                <CircularProgress style={{'color': 'white'}}/>
            ])
            await set_submit_progress_alert([
                <Alert
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_submit_progress_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >

                    Success! Your draft is being saved.
                </Alert>
            ])
        } else {
            await set_submit_button_content([
                <CircularProgress style={{'color': 'white'}}/>
            ])
            await set_submit_progress_alert([
                <Alert
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_submit_progress_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >

                    Success! Please wait a few moments while we upload your trip! This may take up to a few minutes.
                </Alert>
            ])
        }

        upload_trip_to_firestore(trip_JSON, is_draft)
        navigate("/")
    }


    const handle_submit_clicked = async () => {
        await set_submit_buttons_disabled(true)
        const images_master_list = await get_download_URLs()
        submit_trip(images_master_list, false)
    }

    const save_as_draft_button_clicked = async () => {
        await set_submit_buttons_disabled(true)
        const images_master_list = await get_download_URLs()
        submit_trip(images_master_list, true)
    }

    const handle_delete_trip = async () => {
        // delete trip document
        deleteDoc(doc(db, "cities", trip_ID));

        // delete trip ID from user object
        const auth = getAuth()
        const currentUserID = auth.currentUser.uid
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
            // doc.data() is never undefined for query doc snapshots
            var new_trip_IDs_list = document.data()["user_trip_IDs"]

            new_trip_IDs_list = new_trip_IDs_list.filter((elem) => {
                return (elem !== trip_ID)
            })

            const docRef = doc(db, "users", document.id)
            setDoc(docRef, { user_trip_IDs: new_trip_IDs_list }, { merge: true })
        });

        const docRef = doc(db, 'trips', trip_ID);
        setDoc(docRef, {
            body: trip_JSON
        }, { merge: true });

        navigate("/")
    }

    useEffect(() => {
        if ((delete_enabled !== undefined) && (delete_enabled === true)) {
            set_delete_button([
                <Button onClick={() => {
                    const confirmBox = window.confirm(
                        "Are you sure you want to delete this trip? This action cannot be undone. "
                    )
                    if (confirmBox === true) {
                        handle_delete_trip()
                    }
                }}
                        startIcon={<DeleteIcon/>}
                        style={delete_button_styling} >
                    Delete
                </Button>
            ])
        }
    }, [delete_enabled])


    const [submit_button_content, set_submit_button_content] = useState([
        "Submit!"
    ])
    const [save_as_draft_button_content, set_save_as_draft_button_content] = useState([
        "Save as Draft"
    ])

    const [submit_progress_alert, set_submit_progress_alert] = useState([])


    return (
        <div>
            <Navbar navbar_type={"create_trip"}/>

            {submit_progress_alert}

            <Button variant="text"
                    onClick={() => {set_get_started_popup_open(true)}}
                    style={{marginRight: "30%",
                            marginLeft: "auto",
                            marginTop: "10px",
                            display: "block"}}>
                How do I get started?
            </Button>

            <h1 style={create_trip_title_styling}>
                Create Trip
            </h1>

            <Modal
                open={get_started_popup_open}
                onClose={() => {set_get_started_popup_open(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <Box sx={get_started_popup_style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Start by entering a name for your trip. Then click "Add Activity-Group"
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        "Activity Groups" are a way to organize the activities you did on your trip.
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        For example, you can organize activities based on day (day 1, day 2, etc.),
                        or based on location (if perhaps you visited multiple cities throughout your trip).
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        If you prefer to not to use Activity Groups, you can just leave the "Activity Group" text entry blank!
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Once you have created an Activity Group, you can add your activites, along with descriptions and photos.
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={turn_screen_landscape_popup_open}
                onClose={() => {set_turn_screen_landscape_popup_open(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <Box sx={turn_screen_landscape_popup_style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Turn your screen landscape-style for the best experience!
                    </Typography>
                </Box>
            </Modal>


            {delete_button}

            <TextInput
                placeholder=">Enter Trip Name"
                placeholderTextColor="gray"
                style={trip_name_styling}
                value={trip_name}
                onChange={(evt) => {set_trip_name(evt.target.value)}}
            />
            <br></br>

            <div>
                {activities}
            </div>

            <ButtonGroup variant="text"
                         aria-label="outlined primary button group"
                         style={add_remove_activity_group_button_group_styling} >
                <Button onClick={add_activity}
                        startIcon={<AddCircleIcon />} >
                        Add Activity
                </Button>
                <Button onClick={delete_most_recent_activity}
                        endIcon={<RemoveCircleIcon />} >
                    Remove Activity
                </Button>
            </ButtonGroup>



            {submit_progress_alert}

            <Button variant="outlined"
                    onClick={save_as_draft_button_clicked}
                    disabled={submit_buttons_disabled}
                    style={save_as_draft_button_styling}>
                {save_as_draft_button_content}
            </Button>
            <Button variant="contained"
                    onClick={handle_submit_clicked}
                    disabled={submit_buttons_disabled}
                    style={submit_button_styling}>
                {submit_button_content}
            </Button>


        </div>
    )

}

export default CreateTrip;
