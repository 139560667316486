import React from "react";
import { TextInput } from "react-native";
import { useState, useEffect } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { storage } from "../firebase";
import { v4 } from "uuid";
import "./Activity.css"
import Textarea from 'react-expanding-textarea'


export function Activity(props) {
    const activity_name_component_name = props.activity_name_component_name
    const activity_name_list_state = props.activity_name_list_state
    const set_activity_name_list_state = props.set_activity_name_list_state
    const activity_details_list_state = props.activity_details_list_state
    const set_activity_details_list_state = props.set_activity_details_list_state
    const activity_images_list_state = props.activity_images_list_state
    const set_activity_images_list_state = props.set_activity_images_list_state
    const index = props.index
    const activity_initial_state = props.activity_initial_state


    var initial_activity_name = ""
    var initial_activity_details = ""
    var initial_activity_images = []
    if ((activity_initial_state !== undefined) && (activity_initial_state.length === 3)) {
        initial_activity_name = activity_initial_state[0]
        initial_activity_details = activity_initial_state[1]
        initial_activity_images = activity_initial_state[2]
    }
    const [activity_name, set_activity_name] = useState(initial_activity_name)
    const [activity_details, set_activity_details] = useState(initial_activity_details)
    const [images, setImages] = useState(initial_activity_images);

    useEffect(() => {
        const new_activity_name_list_state = activity_name_list_state
        new_activity_name_list_state[index] = activity_name
        set_activity_name_list_state(new_activity_name_list_state)

        const new_activity_details_list_state = activity_details_list_state
        new_activity_details_list_state[index] = activity_details
        set_activity_details_list_state(new_activity_details_list_state)

        const new_activity_images_list_state = activity_images_list_state
        new_activity_images_list_state[index] = images
        set_activity_images_list_state(new_activity_images_list_state)
    })

    const uploadImage = (file) => {
        const storageRef = ref(storage, "images/" + file.name + v4())
        const uploadTask = uploadBytes(storageRef, file)

        uploadTask.then(
            function() {
                getDownloadURL(storageRef).then((downloadURL) => {
                    console.log("Image uploaded successfully. ");
                })
            }
        )
    }



    const activity_name_styling = {
        fontSize: "20px",
        width: "500px",
        height: "20px",
        marginLeft: "160px",
        marginBottom: "5px",
        borderBottomColor: "black",
        borderBottomWidth: "0px",
        paddingBottom: "20px",
        paddingTop: "20px"
    }

    const activity_details_styling = {
        color: "black",
        fontSize: "20px",
        fontFamily: "Arial, Helvetica, sans-serif",
        width: "500px",
        height: "100px",
        border: "0px solid black",
        marginLeft: "160px",
        borderBottomColor: "black",
        borderBottomWidth: "0px"
    }


    function handleChangeActivityName(evt, index) {
        set_activity_name(evt.target.value)
    }

    function handleChangeActivityDetails(evt, index) {
        set_activity_details(evt.target.value)
    }

    const activity_name_onChange =  (event) => handleChangeActivityName(event, index)
    const activity_details_onChange =  (event) => handleChangeActivityDetails(event, index)

    const image_key_generator = () => {
        var lst = []
        for (var i = 0; i < images.length; i++) {lst.push(i)}
        return lst
    }

    const image_styling = {
        height: "100px"
    }
    const image_upload_button_styling = {
        height: "100px"
    }

    const remove_image = (i) => {
        images.splice(i, 1)
        setImages([...images])
    }

    return (
        <>
            <TextInput
                placeholder=">Activity Name"
                placeholderTextColor="gray"
                name={activity_name_component_name}
                style={activity_name_styling}
                value={activity_name}
                onChange={activity_name_onChange}
                key={"activity_name" + index}
            />
            <br></br>

            <Textarea
                placeholder=">Details"
                multiline="true"
                style={activity_details_styling}
                value={activity_details}
                onChange={activity_details_onChange}
                key={"activity_details" + index}
            />

            <br></br>

            Upload Images
            <div className="image_upload">
                {
                    image_key_generator().map((i) => {
                        var src = images[i]
                        if ((typeof src !== 'string')) {
                            src = URL.createObjectURL(images[i])
                        }
                        return (
                        <div className="image_div" key={i} >
                            <img
                                src={src}
                                className="uploaded_image"
                                style={image_styling}
                                onClick={() => {remove_image(i)}} />
                        </div> )
                    })
                }

                <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange = {(event) => {
                        const [file] = event.target.files
                        setImages([
                            ...images,
                            file
                        ])
                    }}
                    style={image_upload_button_styling}
                />
            </div>


        </>
    )
}


export default Activity;
