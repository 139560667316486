import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import {  Navigate } from "react-router";
import { AuthContext } from "./Auth.js";



function RequireAuth({ children }: { children: JSX.Element }) {
  const {currentUser} = useContext(AuthContext);
  let location = useLocation();

  if (!currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default RequireAuth;
