import { db } from "../firebase";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TripActivityDisplay from "./TripActivityDisplay.js"
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Navbar from "./Navbar.js"
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getAuth } from "firebase/auth";
import { collection, doc, getDocs, getDoc, setDoc, query, where, deleteDoc,  } from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from 'react-responsive';


export function TripFullDisplay({route}) {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    var like_button_styling = {
        float: "right",
        clear: "both"
    }
    var trip_full_display_div_styling = {
        width: "70%",
        margin: "auto"
    }
    var delete_button_styling = {
        display: "block",
        clear: "both",
        marginLeft: "50%",
    }

    const navigate = useNavigate()
    const auth = getAuth()
    const [delete_enabled, set_delete_enabled] = useState(false)
    const [delete_button, set_delete_button] = useState([])
    const [edit_enabled, set_edit_enabled] = useState()
    const [edit_button, set_edit_button] = useState([])
    const [like_button_activated, set_like_button_activated] = useState(false)
    const [trip_JSON, set_trip_JSON] = useState({})
    const [trip_name, set_trip_name] = useState("")
    const [activity_elements, set_activity_elements] = useState([])
    const [num_views, set_num_views] = useState(0);
    const [num_likes, set_num_likes] = useState(0)
    const [creator_name, set_creator_name] = useState("")
    const [creator_profile_image, set_creator_profile_image] = useState("")
    const [is_draft, set_is_draft] = useState(true)

    var currentUserID;
    if (auth !== null && auth.currentUser !== null && auth.currentUser.uid !== null) {
        currentUserID = auth.currentUser.uid
    }

    if (isMobile) {
        trip_full_display_div_styling = {
            width: "95%",
            margin: "auto"
        }
    }


    var trip_ID;
    const { id } = useParams();

    // get TRIP_ID from URL or SESSIONSTORAGE
    useEffect(() => {

        if (sessionStorage.getItem("current_viewing_trip_ID") == null || id !== null) {
            // get from shared Link
            trip_ID = id
            sessionStorage.setItem("current_viewing_trip_ID", trip_ID)
        } else {
            trip_ID = sessionStorage.getItem("current_viewing_trip_ID")
        }
    }, [id])


    const manage_view_count = async () => {
        // add this user to the trip's liked-by list
        const docRef = doc(db, 'trips', trip_ID);
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
            var new_view_count = 0;
            if (!isNaN(docSnap.data()["view_count"])) {
                new_view_count = docSnap.data()["view_count"]
            }
            new_view_count++;
            set_num_views(new_view_count);
            setDoc(docRef, {
                view_count: new_view_count
            }, { merge: true });
        }
    }

    // increase view count by 1
    useEffect( () => {
        manage_view_count()
    }, [trip_ID])


    const handle_delete_trip = async () => {
        // delete trip document
        await deleteDoc(doc(db, "trips", trip_ID));

        // delete trip ID from user object
        const currentUserID = auth.currentUser.uid
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
            // doc.data() is never undefined for query doc snapshots
            var new_trip_IDs_list = document.data()["user_trip_IDs"]

            new_trip_IDs_list = new_trip_IDs_list.filter((elem) => {
                return (elem !== trip_ID)
            })

            const docRef = doc(db, "users", document.id)
            setDoc(docRef, { user_trip_IDs: new_trip_IDs_list }, { merge: true })
        });

        navigate("/")
    }


    // sets profile image and the creator of the trip's name
    const set_profile_image = async (user_ID) => {
        const q = query(collection(db, "users"), where("user_ID", "==", user_ID));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (document) => {
            if ((document.data()["user_profile_image_download_URL"] !== undefined) && (document.data()["user_profile_image_download_URL"] !== "") ) {
                set_creator_profile_image(document.data()["user_profile_image_download_URL"])
                set_creator_name(document.data()["user_name"])
            }
        });
    }

    // gets TRIP_JSON, sets profile image, sets number of likes, sets like button activated/deactivated
    const get_trip_JSON = async () => {
        const docRef = doc(db, "trips", trip_ID)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await set_trip_JSON(docSnap.data()["body"])
            await set_is_draft(docSnap.data()["is_draft"])
            await set_profile_image(docSnap.data()["creator"])
            // count number of likes
            const num_likes = docSnap.data()["liked_by"].length
            set_num_likes(num_likes)
            if (currentUserID === undefined) {return}
            if (docSnap.data()["liked_by"].includes(currentUserID)) {
                set_like_button_activated(true)
            }
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    // checks if this trip belongs to the current user
    const check_edit_enabled = async () => {
        if (currentUserID === undefined) {return}
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
            if ((document.data()["user_trip_IDs"] !== undefined) && (document.data()["user_trip_IDs"].includes(trip_ID)) ) {
                set_edit_enabled(true)
                set_delete_enabled(true)
                sessionStorage.setItem("delete_enabled", true)
                set_delete_button([
                    <Button onClick={() => {
                        const confirmBox = window.confirm(
                            "Are you sure you want to delete this trip? This action cannot be undone. "
                        )
                        if (confirmBox === true) {
                            handle_delete_trip()
                        }
                    }}
                            startIcon={<DeleteIcon/>}
                            style={delete_button_styling} >
                        Delete
                    </Button>
                ])

                const edit_button_styling = {}
                set_creator_name([])
                set_avatar([])
                set_creator_profile_image("")
                set_edit_button([
                    <Button onClick={() => {navigate("/edit_trip",
                                                        {state:{
                                                            trip_ID: trip_ID
                                                        }})}}
                            startIcon={<EditIcon/>}
                            style={edit_button_styling} >
                        Edit
                    </Button>
                ])
            }
        });
    }

    // when TRIP_ID changes, get the TRIP_JSON and set EDIT_ENABLED
    useEffect(() => {
        get_trip_JSON()
        if (trip_ID !== undefined) {
            check_edit_enabled()
        }

    }, [trip_ID])

    // populate the trip content
    useEffect(() => {
        if (Object.keys(trip_JSON).length > 0) {

            set_trip_name(trip_JSON["trip_name"])


            const activity_elements = []
            trip_JSON["activities"].forEach((activity) => {
                activity_elements.push(
                    <TripActivityDisplay activity_JSON={activity} />
                )
            })
            set_activity_elements(activity_elements)
        }
    }, [trip_JSON])



    // called when user likes or unlikes the post
    const toggle_like_button = async () => {
        if (currentUserID === undefined) {navigate("/signup")}
        set_like_button_activated(prev_like_button_activated => !prev_like_button_activated)
        // already have trip_ID
        if (!like_button_activated === true) {
            // add this trip to user's liked-trip list
            const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((document) => {
                const new_liked_trips_list = document.data()["user_liked_trips"]
                new_liked_trips_list.push(trip_ID)

                const docRef = doc(db, "users", document.id)
                setDoc(docRef, { user_liked_trips: new_liked_trips_list }, { merge: true })
            });

            // add this user to the trip's liked-by list
            const docRef = doc(db, 'trips', trip_ID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const new_liked_by_list = docSnap.data()["liked_by"]
                new_liked_by_list.push(currentUserID)

                setDoc(docRef, {
                    liked_by: new_liked_by_list
                }, { merge: true });
            }

        } else {
            // remove this trip from user's liked-trip list
            const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((document) => {
                var new_liked_trips_list = document.data()["user_liked_trips"]
                new_liked_trips_list = new_liked_trips_list.filter((elem) => {
                    return (elem !== trip_ID)
                })

                const docRef = doc(db, "users", document.id)
                setDoc(docRef, { user_liked_trips: new_liked_trips_list }, { merge: true })
            });

            // remove this user from trip's liked-by list
            const docRef = doc(db, 'trips', trip_ID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var new_liked_by_list = document.data()["liked_by"]
                new_liked_by_list = new_liked_by_list.filter((elem) => {
                    return (elem !== currentUserID)
                })
                new_liked_by_list.push(currentUserID)

                setDoc(docRef, {
                    liked_by: new_liked_by_list
                }, { merge: true });
            }
        }
    }

    const [like_button, set_like_button] = useState([
        <Button onClick={toggle_like_button}
                startIcon={<FavoriteBorderIcon/>}
                style={like_button_styling} >
            Like
        </Button>
    ])

    // toggles like button between filled-in-heart and empty-heart
    useEffect(() => {
        if (like_button_activated === true) {
            set_like_button([
                <Button onClick={toggle_like_button}
                        startIcon={<FavoriteIcon/>}
                        style={like_button_styling} >
                    Like
                </Button>
            ])
            set_num_likes(prev_num_likes => prev_num_likes+1)
        } else {
            set_like_button([
                <Button onClick={toggle_like_button}
                        startIcon={<FavoriteBorderIcon/>}
                        style={like_button_styling} >
                    Like
                </Button>
            ])
            if (num_likes > 0) {
                set_num_likes(prev_num_likes => prev_num_likes-1)
            }
        }
    }, [like_button_activated])


    const [avatar, set_avatar] = useState([
        <Avatar sx={{ width: 100, height: 100 }}>
        </Avatar>
    ])

    useEffect(() => {
        set_avatar([
            <Avatar sx={{ width: 100, height: 100 }}
                    src={creator_profile_image}
                    style={{float: "right", display: "block", marginLeft: "50%", marginTop: "20px"}}>
            </Avatar>
        ])
    }, [creator_profile_image])

    const [name, set_name] = useState([
        <h3>
        </h3>
    ])
    useEffect(() => {
        set_name([
            <h3 style={{float: "right", display: "block", marginLeft: "50%"}}>
                {creator_name}
            </h3>
        ])
    }, [creator_name])


    return (
            <div>
                <Navbar navbar_type={"trip_full_display_page"}/>

                <div className="trip_full_display_div"
                    style={trip_full_display_div_styling} >

                    {avatar}
                    {name}

                    <div style={like_button_styling}>
                        <h3>
                            {num_views} Views
                        </h3>
                        <h3>
                            {num_likes} Likes
                        </h3>
                        {like_button}
                    </div>

                    <h1 style={{clear: "both", fontSize: "60px"}}>
                        {trip_name}
                    </h1>
                    {edit_button}

                    <div className="activity_groups_div"  >
                        {activity_elements}
                    </div>

                    {delete_button}

                </div>
            </div>

    )
}


export default TripFullDisplay;
