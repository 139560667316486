import './TripActivityDisplay.css';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from "react";



export function TripActivityDisplay(props) {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const activity_JSON = props.activity_JSON
    const images_list = activity_JSON["activity_images_download_URLs_list"]
    const [activity_content_margin_left, set_activity_content_margin_left] = useState("150px")

    const activity_name_styling = {
        display: "block",
        textAlign: "left",
        marginLeft: activity_content_margin_left,
        marginRight: "5px",
    }
    const activity_details_styling = {
        textAlign: "left",
        marginLeft: activity_content_margin_left,
        marginRight: "5px",
    }

    const image_elements = []
    const image_styling = {
        width: "250px",
        borderRadius: "10px",
        margin: "10px",
        float: "left"
    }
    const activity_images_div_styling = {
        marginLeft: activity_content_margin_left,
        marginRight: "5px",
    }
    images_list.forEach((download_URL) => {
        image_elements.push(
            <img
                src={download_URL}
                style={image_styling}
                className="image_element"
            />
        )
    })

    useEffect(() => {
        if (isMobile) {
            set_activity_content_margin_left("30px")
        }
    }, [])

    return (
        <div className="trip_activity_display_div"
             style={{display: "block", clear: "both"}}>
            <h3 style={activity_name_styling}>
                {activity_JSON["activity_name"]}
            </h3>
            <p style={activity_details_styling}>
                {activity_JSON["activity_details"]}
            </p>
            <div className="activity_images_div"
                 style={activity_images_div_styling}>
                {image_elements}
            </div>
        </div>
    )

}

export default TripActivityDisplay;
