import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection,
         query,
         orderBy,
         startAfter,
         limit,
         getDocs,
} from "firebase/firestore";
import HighlightsHeader from "./HighlightsHeader.js"
import TripEntry from "./TripEntry.js"
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Navbar from "./Navbar.js"


import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
} from 'react-instantsearch-hooks-web';
import { InfiniteHits } from './InfiniteHits.js';




export function Homepage(props) {
    const batch_size = 3
    const [trip_entries, set_trip_entries] = useState([])
    const [start_after, set_start_after] = useState(0)
    sessionStorage.removeItem("current_viewing_trip_ID")


    const searchClient = algoliasearch(
      'Q6AH87AMNR',
      '296cabe6fa736178f8cec739e9a8f3e1'
    );

    return (
        <>
            <Navbar navbar_type={"homepage"}/>


            <HighlightsHeader header_text="Highlights For You" className="highlights_text"/>

            <div className="container">
                <InstantSearch indexName="trips" searchClient={searchClient}>
                    <SearchBox searchAsYouType={true}
                               placeholder= "Search for a place"/>
                    <InfiniteHits hitComponent={Hit} />
                </InstantSearch>
            </div>


        </>
    )
}


function Hit({ hit }) {
    // do not display drafts
    if (hit["is_draft"] === true) {
        return
    } else {
        return (
            <article>
                <TripEntry trip_ID={hit.objectID} trip_name={hit["body"]["trip_name"]} />
            </article>
        );
    }
}

export default Homepage;
