import React, { useContext, useState } from "react";
import {  Navigate } from "react-router";
import { AuthContext } from "../Auth.js";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Button, TextField, Alert } from '@mui/material';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar.js"


function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}


const Login = ({ history }) => {
    const navigate = useNavigate();
    const [email_entered, set_email_entered] = useState("")
    const [password_entered, set_password_entered] = useState("")
    const [login_error_alert, set_login_error_alert] = useState([])

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(getAuth(), email_entered, password_entered);
            history.push("/");
        } catch (error) {
            console.log(error);
            set_login_error_alert([
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => {set_login_error_alert([])}}>
                        CLOSE
                        </Button>
                    }
                    >
                    {error.code}
                </Alert>
            ])
        }
    }

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Navigate to="/" />;
    }

    const text_field_styling = {
        marginTop: "20px"
    }

    return (
        <div>
            <Navbar navbar_type={"login_page"}/>

            {login_error_alert}


            <form onSubmit={handleLogin}>
                <TextField label="Email"
                           value={email_entered}
                           onChange={(event) => {set_email_entered(event.target.value)}}
                           variant="outlined"
                           type="email"
                           style={text_field_styling} />

                <TextField label="Password"
                           value={password_entered}
                           onChange={(event) => {set_password_entered(event.target.value)}}
                           variant="outlined"
                           type="password"
                           style={text_field_styling} />

                <Button type="submit"
                        style={{display: "block", margin: "auto"}}>
                    Log In
                </Button>
            </form>

            <p> or </p>
            <Button onClick={() => {navigate("/signup")}}>
                Sign Up
            </Button> <br></br>
            <p> or </p>
            <Button onClick={() => {navigate("/password_reset")}}>
                forgot my password?
            </Button> <br></br>

        </div>
    );
};

export default withRouter(Login);
