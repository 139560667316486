import Navbar from "./Navbar.js"
import React, { useState } from "react";
import { Button, TextField } from '@mui/material';
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";



export function Contact(props) {
    const [name, set_name] = useState("")
    const [email, set_email] = useState("")
    const [message, set_message] = useState("")
    const [directions, set_directions] = useState([
        <h1 key="directions_message">
            Questions? Comments? Suggestions? Concerns? Please fill out this form to let us know!
        </h1>
    ])

    const handle_form_submit = async (event) => {
        event.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "user_questions_comments_suggestions_concerns"), {
                name: name,
                email: email,
                message: message,
                created_at: Date.now()
            });

            set_name("")
            set_email("")
            set_message("")
            set_directions([
                <h1 key="submit_message">
                    Thanks for submitting your message! We will be in touch soon.
                </h1>
            ])
        } catch (error) {
            console.log(error);
        }

    }

    const name_text_field_styling = {
        margin: "20px",
        width: "51%"
    }
    const email_text_field_styling = {
        margin: "20px",
        width: "51%"
    }
    const message_text_field_styling = {
        margin: "20px",
        width: "51%"
    }

    return (
        <>
            <Navbar navbar_type={"contact"}/>

            {directions}



            <form onSubmit={handle_form_submit}>
                <TextField label="Name"
                           value={name}
                           onChange={(event) => {set_name(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={name_text_field_styling} />

                <TextField label="Email"
                           value={email}
                           onChange={(event) => {set_email(event.target.value)}}
                           variant="outlined"
                           type="email"
                           style={email_text_field_styling} />

                <TextField label="Message"
                           multiline
                           value={message}
                           onChange={(event) => {set_message(event.target.value)}}
                           variant="outlined"
                           type="text"
                           style={message_text_field_styling}
                            />

                <Button type="submit"
                        variant="contained"
                        style={{display: "block", margin: "auto"}}>
                    Submit
                </Button>
            </form>
        </>

    )
}

export default Contact;
