// JavaScript
// src/firebase.js
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCmcd7gCPn682rY1tizgl8OcJNmqxVpZEI",
  authDomain: "tripsharer-f1ce3.firebaseapp.com",
  projectId: "tripsharer-f1ce3",
  storageBucket: "tripsharer-f1ce3.appspot.com",
  messagingSenderId: "714957577190",
  appId: "1:714957577190:web:c347e5444a6ced41a39427"
};



// JavaScript
// src/firebase.js
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app);

export {app, db, storage}
