import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import Avatar from '@mui/material/Avatar';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar.js"
import TripEntry from "./TripEntry.js"
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SaveAsIcon from '@mui/icons-material/SaveAs';


export function Profile() {
    const your_trips_styling = {
        display: "block",
        textAlign: "left",
        marginLeft: "10%",
        marginRight: "10%",
        borderBottom: "5px solid black",
    }

    const edit_button_styling = {
        marginLeft: "75%",
        marginTop: "20px",
    }


    const navigate = useNavigate();
    const auth = getAuth()
    const currentUserID = auth.currentUser.uid

    const [trip_IDs, set_trip_IDs] = useState([])
    const [trips_list, set_trips] = useState([])
    const [user_name, set_user_name] = useState("Loading... ")
    const [user_profile_image, set_user_profile_image] = useState("")

    const avatar_styling = {
        display: "block",
        margin: "auto",
    }
    const [avatar, set_avatar] = useState([
        <Avatar key="avatar"
                sx={{ width: 100, height: 100 }}
                style={avatar_styling}>
        </Avatar>
    ])


    const get_user_data = async () => {
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (document) => {
            // doc.data() is never undefined for query doc snapshots
            await set_trip_IDs(document.data()["user_trip_IDs"])
            await set_user_name(document.data()["user_name"])
            if ((document.data()["user_profile_image_download_URL"] !== undefined) && (document.data()["user_profile_image_download_URL"] !== "") ) {
                set_user_profile_image(document.data()["user_profile_image_download_URL"])
            }
        });
    }

    useEffect(() => {
        get_user_data()
    }, [])

    const trip_is_draft = async (tid) => {
        const docRef = doc(db, "trips", tid)
        const docSnap = await getDoc(docRef)
        console.log("docsnap exists: ", docSnap.exists())
        console.log("docsnap is_draft: ", docSnap.data()["is_draft"] === true)
        if (docSnap.exists() && docSnap.data()["is_draft"] === true) {
            return [true]
        } else {
            return [false]
        }
    }

    useEffect(() => {
        if (trip_IDs !== undefined && trip_IDs.length > 0) {
            const trips = []
            trip_IDs.forEach(async (trip_ID) => {
                const is_draft = await trip_is_draft(trip_ID)
                console.log(!is_draft[0])
                if (!is_draft[0]) {
                    console.log("Bruh")
                    await set_trips(prev_state =>[
                            ...prev_state,
                            <TripEntry trip_ID={trip_ID}
                                       edit_enabled={true} />
                        ]
                    )
                }
            })

        }

    }, [trip_IDs])

    useEffect(() => {
        set_avatar([
            <Avatar key="avatar"
                    sx={{ width: 100, height: 100 }}
                    style={avatar_styling}
                    src={user_profile_image} >
            </Avatar>
        ])
    }, [user_profile_image])




    return (
        <>
            <Navbar navbar_type="profile_page" />

            <Button onClick={() => {navigate("/edit_profile")}}
                    startIcon={<EditIcon/>}
                    style={edit_button_styling} >
                Edit Profile
            </Button>
            <Button onClick={() => {navigate("/liked_trips")}}
                    startIcon={<FavoriteIcon/>}
                    style={edit_button_styling} >
                Liked Posts
            </Button>
            <Button onClick={() => {navigate("/drafts")}}
                    startIcon={<SaveAsIcon/>}
                    style={edit_button_styling} >
                My Drafts
            </Button>

            {avatar}

            <h1>
                {user_name}
            </h1>



            <h1 style={your_trips_styling}>
                My Trips
            </h1>
            {trips_list}
        </>
    )

}


export default Profile;
