import './HighlightsHeader.css'

// props attributes:
    // header_text (string)
export function HighlightsHeader(props) {
    const header_styling = {
        textAlign: "left",
        marginLeft: "10%",
        marginRight: "10%",
        fontSize: "35px",
        borderBottom: "5px solid black",
    }

    return (
        <h2 style={header_styling}>
            {props.header_text}
        </h2>
    )
}


export default HighlightsHeader;
