import React, { useEffect, useState } from "react";
import { collection, addDoc, doc, getDocs, getDoc, setDoc, query, where, deleteDoc } from "firebase/firestore";
import { storage, db } from "../firebase";
import Navbar from "./Navbar.js"
import { useNavigate, useLocation } from "react-router-dom";
import EditTripActivity from "./EditTripActivity.js"
import { Button, ButtonGroup, CircularProgress, Alert, Modal, Typography, Box, Badge } from '@mui/material';
import { TextInput } from "react-native";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


export function EditTrip(props) {
    const create_trip_title_styling = {
        fontSize: "50px",
        marginTop: "0px",
        marginBottom: "15px",
        marginLeft: "10%",
        marginRight: "10%",
        borderBottom: "5px solid black",
    }
    const get_started_popup_style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
    const turn_screen_landscape_popup_style = {
        width: "200px",
        padding: "20px",
        marginTop: "30%",
        marginLeft: "10%",
        bgcolor: 'background.paper',
        boxShadow: 24,
    }
    const trip_name_styling = {
        fontSize: "50px",
        borderBottomColor: "black",
        borderBottomWidth: "0px",
        marginBottom: "25px",
        display: "block",
        margin: "auto"
    }
    const add_remove_activity_group_button_group_styling = {
        marginTop: "100px"
    }
    const submit_button_styling = {
        display: "block",
        margin: "auto",
        marginTop: "20px",
        marginBottom: "100px",
    }
    const save_as_draft_button_styling = {
        display: "block",
        margin: "auto",
        marginTop: "100px",
    }


    const navigate = useNavigate()
    const [submit_progress_alert, set_submit_progress_alert] = useState([])
    const [get_started_popup_open, set_get_started_popup_open] = useState(false)
    const [turn_screen_landscape_popup_open, set_turn_screen_landscape_popup_open] = useState(false)
    const [delete_button, set_delete_button] = useState([])
    const [submit_button_content, set_submit_button_content] = useState([
        "Submit!"
    ])
    const [save_as_draft_button_content, set_save_as_draft_button_content] = useState([
        "Save as Draft"
    ])


    // asks user to confirm before leaving the page (via refresh)
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    const get_trip_JSON = async (trip_ID) => {
        const docRef = doc(db, "trips", trip_ID)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await set_trip_JSON(docSnap.data()["body"])
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    // const add_activity_group = async (trip_JSON, index, create_new) => {
    //     if (create_new) {
    //         set_trip_JSON(prev_state => {
    //             const new_activity_group = {
    //                 activity_group_name: "",
    //                 activities: [],
    //             }
    //             prev_state["activity_groups"].push(new_activity_group)
    //             return prev_state
    //         })
    //     }
    //
    //
    //     await set_activity_groups(previous_activity_groups => [...previous_activity_groups,
    //                         <EditTripActivityGroup key={index}
    //                                                index={index}
    //                                                trip_JSON = {trip_JSON}
    //                                                set_trip_JSON = {set_trip_JSON} />])
    // }

    const location = useLocation()
    const trip_ID = location.state.trip_ID

    const [trip_JSON, set_trip_JSON] = useState({})
    const [trip_name, set_trip_name] = useState("")
    const [activities, set_activities] = useState([])
    const [initial_trip_JSON_load_complete, set_initial_trip_JSON_load_complete] = useState(false)


    const add_activity = async (trip_JSON, index, create_new) => {
        if (create_new) {
            set_trip_JSON(prev_state => {
                const new_activity = {
                    activity_name: "",
                    activity_details: "",
                    activity_images_download_URLs_list: []
                }
                prev_state["activities"].push(new_activity)
                return prev_state
            })
        }

        await set_activities(previous_activities => [...previous_activities,
                            <EditTripActivity key={index}
                                              activity_index={index}
                                              trip_JSON = {trip_JSON}
                                              set_trip_JSON = {set_trip_JSON} />])
    }
    const delete_most_recent_activity = () => {
        activities.pop()
        set_activities([...activities])
    }


    useEffect(() => {
        get_trip_JSON(trip_ID)
    }, [])

    useEffect(() => {
        if (!initial_trip_JSON_load_complete && Object.keys(trip_JSON).length > 0) {
            set_initial_trip_JSON_load_complete(true)
            set_trip_name(trip_JSON["trip_name"])

            trip_JSON["activities"].forEach((activity, index) => {
                add_activity(trip_JSON, index, false)
            })
        }

    }, [trip_JSON])

    const delete_most_recent_activity_group = () => {
        activities.pop()
        set_activities([...activities])
    }

    const handle_submit_clicked = async (tj) => {
        await setDoc(doc(db, "trips", trip_ID), {
            body: tj,
            is_draft: false
        }, { merge: true });

        navigate("/")
    }

    const save_as_draft_button_clicked = async (tj) => {
        set_save_as_draft_button_content([
            <CircularProgress style={{'color': 'white'}}/>
        ])

        set_submit_progress_alert([
            <Alert
                action={
                    <Button color="inherit" size="small" onClick={() => {set_submit_progress_alert([])}}>
                    CLOSE
                    </Button>
                }
                >

                Success! Your draft is being saved.
            </Alert>
        ])

        // actually submit the trip to firebase, make sure IS_DRAFT = TRUE
        await setDoc(doc(db, "trips", trip_ID), {
            body: tj,
            is_draft: true
        }, { merge: true });

        navigate("/")
    }




    return (
        <div>
            <Navbar navbar_type={"create_trip"}/>

            {submit_progress_alert}

            <Button variant="text"
                    onClick={() => {set_get_started_popup_open(true)}}
                    style={{marginRight: "30%",
                            marginLeft: "auto",
                            marginTop: "10px",
                            display: "block"}}>
                How do I get started?
            </Button>

            <h1 style={create_trip_title_styling}>
                Edit Trip
            </h1>

            <Modal
                open={get_started_popup_open}
                onClose={() => {set_get_started_popup_open(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <Box sx={get_started_popup_style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Start by entering a name for your trip. Then click "Add Activity-Group"
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        "Activity Groups" are a way to organize the activities you did on your trip.
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        For example, you can organize activities based on day (day 1, day 2, etc.),
                        or based on location (if perhaps you visited multiple cities throughout your trip).
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        If you prefer to not to use Activity Groups, you can just leave the "Activity Group" text entry blank!
                    </Typography>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Once you have created an Activity Group, you can add your activites, along with descriptions and photos.
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={turn_screen_landscape_popup_open}
                onClose={() => {set_turn_screen_landscape_popup_open(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <Box sx={turn_screen_landscape_popup_style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Turn your screen landscape-style for the best experience!
                    </Typography>
                </Box>
            </Modal>




            {delete_button}

            <TextInput
                placeholder="Enter Trip Name"
                placeholderTextColor="gray"
                style={trip_name_styling}
                value={trip_name}
                onChange={async (evt) => {
                    set_trip_name(evt.target.value)
                    await set_trip_JSON(prev_state => {
                        prev_state["trip_name"] = evt.target.value
                        return prev_state
                    })
                }}
            />
            <br></br>

            <div>
                {activities}
            </div>

            <ButtonGroup variant="text"
                         aria-label="outlined primary button group"
                         style={add_remove_activity_group_button_group_styling} >
                <Button onClick={() => {
                    add_activity(trip_JSON, trip_JSON["activities"].length, true)
                }}
                        startIcon={<AddCircleIcon />} >
                        Add Activity-Group
                </Button>
                <Button onClick={delete_most_recent_activity_group}
                        endIcon={<RemoveCircleIcon />} >
                    Remove Activity-Group
                </Button>
            </ButtonGroup>

            {submit_progress_alert}

            <Button variant="outlined"
                    onClick={() => {
                        save_as_draft_button_clicked(trip_JSON)
                    }}
                    style={save_as_draft_button_styling}>
                {save_as_draft_button_content}
            </Button>
            <Button variant="contained"
                    onClick={() => {
                        handle_submit_clicked(trip_JSON)
                    }}
                    style={submit_button_styling}>
                {submit_button_content}
            </Button>

        </div>
    )



}


export default EditTrip;
