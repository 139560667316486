import Navbar from "./Navbar.js"
import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import TripEntry from "./TripEntry.js"




export function Drafts(props) {
    const your_trips_styling = {
        display: "block",
        textAlign: "left",
        marginLeft: "10%",
        marginRight: "10%",
        borderBottom: "5px solid black",
    }

    const navigate = useNavigate();
    const auth = getAuth()
    const currentUserID = auth.currentUser.uid

    const [drafts_list, set_drafts_list] = useState([])
    const [trip_IDs, set_trip_IDs] = useState([])


    const get_user_data = async () => {
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (document) => {
            // doc.data() is never undefined for query doc snapshots
            await set_trip_IDs(document.data()["user_trip_IDs"])
        });
    }

    useEffect(() => {
        get_user_data()
    }, [])

    const trip_is_draft = async (tid) => {
        const docRef = doc(db, "trips", tid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists() && docSnap.data()["is_draft"] === true) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (trip_IDs !== undefined && trip_IDs.length > 0) {
            trip_IDs.forEach(async (trip_ID) => {
                const is_draft = await trip_is_draft(trip_ID)
                if (is_draft === true) {
                    await set_drafts_list(prev_state => [
                        ...prev_state,
                        <TripEntry trip_ID={trip_ID}
                                   edit_enabled={true} />
                    ])
                }
            })
        }

    }, [trip_IDs])



    return (
            <div>
                <Navbar navbar_type={"drafts_page"}/>

                <h1 style={your_trips_styling}>
                    My Drafts
                </h1>

                { drafts_list }


            </div>

    )

}


export default Drafts;
