import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from "./Auth.js";
import CreateTrip from "./components/CreateTrip.js"
import Homepage from "./components/Homepage.js"
import Login from "./components/Login.js"
import Signup from "./components/Signup.js"
import Signout from "./components/Signout.js"
import RequireAuth from "./RequireAuth.js"
import TripFullDisplay from "./components/TripFullDisplay.js"
import Profile from "./components/Profile.js"
import PasswordReset from "./components/PasswordReset.js"
import PrivacyPolicy from "./components/PrivacyPolicy.js"
import Contact from "./components/Contact.js"
import EditProfile from "./components/EditProfile.js"
import EditTrip from "./components/EditTrip.js"
import LikedTrips from "./components/LikedTrips.js"
import Drafts from "./components/Drafts.js"


function App() {

    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Homepage/>} />
                        <Route path="/login" element={<Login/>} />
                        <Route path="/password_reset" element={<PasswordReset/>} />
                        <Route path="/signup" element={<Signup/>} />
                        <Route path="/trip_full_display/:trip_name_param/:id" element={<TripFullDisplay/>} />
                        <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
                        <Route path="/contact" element={<Contact/>} />

                        <Route
                            path="/edit_profile"
                            element={
                                <RequireAuth>
                                    <EditProfile />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/signout"
                            element={
                                <RequireAuth>
                                    <Signout />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <RequireAuth>
                                    <Profile />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/liked_trips"
                            element={
                                <RequireAuth>
                                    <LikedTrips />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/create_trip"
                            element={
                                <RequireAuth>
                                    <CreateTrip />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/edit_trip"
                            element={
                                <RequireAuth>
                                    <EditTrip />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/drafts"
                            element={
                                <RequireAuth>
                                    <Drafts />
                                </RequireAuth>
                            }
                        />

                    </Routes>


                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
