import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import Navbar from "./Navbar.js"
import TripEntry from "./TripEntry.js"
import { doc, getDoc } from "firebase/firestore";






export function LikedTrips(props) {

    const [liked_trips_ID_list, set_liked_trips_ID_list] = useState([])
    const [trip_entries, set_trip_entries] = useState([])

    const auth = getAuth()
    const currentUserID = auth.currentUser.uid


    // populate liked_trips_ID_list using currentUserID
    useEffect( () => {
        async function get_liked_trip_IDs() {
            const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach( (document) => {
                // doc.data() is never undefined for query doc snapshots
                var user_liked_trips_list = document.data()["user_liked_trips"]
                set_liked_trips_ID_list(user_liked_trips_list)
            });
        }
        get_liked_trip_IDs()
    }, [])

    async function trip_exists(tid) {
        const docRef = doc(db, "trips", tid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return true
        } else {
            return false
        }
    }

    // create a trip entry for each trip ID in liked_trips_ID_list
    useEffect(() => {
        if (liked_trips_ID_list.length > 0) {
            const lst = []
            liked_trips_ID_list.forEach(async (trip_ID) => {
                const exists = await trip_exists(trip_ID)
                if (exists) {
                    set_trip_entries(prev_state => [
                        ...prev_state,
                        <article key={trip_ID + "key"}>
                            <TripEntry trip_ID={trip_ID} trip_name={"LOADING... "} />
                        </article>
                    ])
                }

            })
        }

    }, [liked_trips_ID_list])



    const liked_trips_styling = {
        display: "block",
        textAlign: "left",
        marginLeft: "10%",
        marginRight: "10%",
        borderBottom: "5px solid black",
    }



    return (
        <div>
            <Navbar navbar_type={"liked_trips"}/>

            <h1 style={liked_trips_styling}>
                Liked Trips
            </h1>

            {trip_entries}

        </div>
    )


}


export default LikedTrips;
