import Navbar from "./Navbar.js"
import { getAuth } from "firebase/auth";
import React, { useEffect, useState, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import { collection, doc, getDocs, setDoc, query, where } from "firebase/firestore";
import { storage, db } from "../firebase";
import { Button, Alert } from '@mui/material';
import { TextInput } from "react-native";
import AvatarEditor from 'react-avatar-editor'
import { Box, Slider } from "@material-ui/core";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 } from "uuid";



export function EditProfile() {

    // populate user profile image
    // populate user name in text field

    const auth = getAuth()
    const currentUserID = auth.currentUser.uid

    const [user_name, set_user_name] = useState("Loading... ")
    const [user_profile_image, set_user_profile_image] = useState("https://commons.wikimedia.org/wiki/File:Default_pfp.jpg")
    const [success_alert, set_success_alert] = useState([])
    const image_upload_ref = useRef(null);

    const avatar_styling = {
        display: "block",
        margin: "auto",
        marginTop: "100px"
    }
    const [avatar, set_avatar] = useState([
        <Avatar sx={{ width: 100, height: 100 }}
                style={avatar_styling}>
        </Avatar>
    ])


    const get_user_data = async () => {
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (document) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(document.id, " => ", document.data());
            await set_user_name(document.data()["user_name"])
            if ((document.data()["user_profile_image_download_URL"] !== undefined) && (document.data()["user_profile_image_download_URL"] !== "") ) {
                console.log("UPDATED PROFILE IMAGE: ", document.data()["user_profile_image_download_URL"])
                set_user_profile_image(document.data()["user_profile_image_download_URL"])
            }
        });
    }

    useEffect(() => {
        get_user_data()
    }, [])


    useEffect(() => {
        set_avatar([
            <Avatar sx={{ width: 100, height: 100 }}
                    style={avatar_styling}
                    src={user_profile_image} >
            </Avatar>
        ])
    }, [user_profile_image])


    const submit_button_styling = {
        display: "block",
        margin: "auto",
        marginTop: "200px",
    }
    const user_name_styling = {
        fontSize: "40px",
        width: "500px",
        height: "20px",
        marginBottom: "5px",
        marginTop: "50px",
        borderBottomColor: "black",
        borderBottomWidth: "0px",
        paddingBottom: "20px",
        paddingTop: "20px",
        textAlign: "center"
    }
    const title_text_styling = {
        marginLeft: "10%",
        marginRight: "10%",
        borderBottom: "5px solid black",
    }

    const close_alert = () => {
        set_success_alert([])
    }

    const handle_submit_clicked = () => {
        set_success_alert([
            <Alert
                action={
                    <Button color="inherit" size="small" onClick={close_alert}>
                    CLOSE
                    </Button>
                }
                >
                Success! Your profile information has been updated.
            </Alert>
        ])
        // handles both image upload/update and name update
        uploadImage(image_upload_ref.current.files[0])
        // navigate("/profile")
    }

    async function update_user_profile_image_download_URL_and_user_name(downloadURL) {
        const auth = getAuth()
        const currentUserID = auth.currentUser.uid
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
            // doc.data() is never undefined for query doc snapshots
            const docRef = doc(db, "users", document.id)
            setDoc(docRef, {
                user_profile_image_download_URL: downloadURL,
            }, { merge: true })
        });
    }

    async function uploadImage(file) {
        const auth = getAuth()
        const currentUserID = auth.currentUser.uid
        const q = query(collection(db, "users"), where("user_ID", "==", currentUserID));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((document) => {
            // doc.data() is never undefined for query doc snapshots
            const docRef = doc(db, "users", document.id)
            setDoc(docRef, {
                user_name: user_name
            }, { merge: true })
        });

        if (typeof file === 'string' && file.includes("firebasestorage")) {
            return
        }
        const storageRef = ref(storage, "images/" + file.name + v4())
        await uploadBytes(storageRef, file)
        await getDownloadURL(storageRef).then((downloadURL) => {
            // set user's document to have this download URL for profile image
            update_user_profile_image_download_URL_and_user_name(downloadURL)
        })
    }







    useEffect(() => {
        setPicture({
            ...picture,
            img: null,
            cropperOpen: false,
            croppedImg: user_profile_image
        })
    }, [user_profile_image])



    var editor = "";
    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 2,
        croppedImg: "https://commons.wikimedia.org/wiki/File:Default_pfp.jpg"

    });

    const handleSlider = (event, value) => {
        setPicture({
            ...picture,
            zoom: value
        });
    };

    const handleCancel = () => {
        setPicture({
            ...picture,
            cropperOpen: false
        });
    };

    const setEditorRef = (ed) => {
        editor = ed;
    };

    const handleSave = (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();

            setPicture({
                ...picture,
                img: null,
                cropperOpen: false,
                croppedImg: croppedImg
            });
        }
    };

    const handleFileChange = (e) => {
        let url = URL.createObjectURL(e.target.files[0]);
        setPicture({
            ...picture,
            img: url,
            cropperOpen: true
        });
    };



    return (
        <>
            <Navbar navbar_type="edit_profile" />
            {success_alert}

            <h1 style={title_text_styling}>
                Edit Profile
            </h1>

            <div>
                    <Box width="20%" display="block" style={{marginLeft: "40%"}}>
                        <Avatar
                            src={picture.croppedImg}
                            style={{ width: "100%", height: "auto", padding: "5" }} />

                        <input type="file" accept="image/*" onChange={handleFileChange} ref={image_upload_ref} />
                    </Box>



                    {picture.cropperOpen && (
                    <>
                        <h2>
                            Adjust your image.
                        </h2>
                        <Box display="block" width="20%" style={{marginLeft: "40%", marginTop: "30px"}}>
                            <AvatarEditor
                                ref={setEditorRef}
                                image={picture.img}
                                width={200}
                                height={200}
                                border={50}
                                color={[255, 255, 255, 0.6]} // RGBA
                                rotate={0}
                                scale={picture.zoom}
                            />
                            <Slider
                                aria-label="raceSlider"
                                value={picture.zoom}
                                min={1}
                                max={10}
                                step={0.1}
                                onChange={handleSlider}
                                ></Slider>
                            <Box>
                                <Button variant="contained" onClick={handleCancel}>
                                    Cancel
                                </Button>
                                <Button onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </>

                    )}
            </div>


            <TextInput
                placeholder="Name"
                placeholderTextColor="gray"
                name={user_name}
                style={user_name_styling}
                value={user_name}
                onChange={(evt) => {set_user_name(evt.target.value)}}
                key={"user_name_text_input"}
            />


            <Button variant="contained"
                    onClick={handle_submit_clicked}
                    style={submit_button_styling}>
                Submit!
            </Button>

        </>
    )

}

export default EditProfile;
