import { getAuth, signOut } from "firebase/auth";


export function Signout() {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
        return (
            <h1>
                You have successfully signed out!
            </h1>
        )
    }).catch((error) => {
        // An error happened.
        return (
            <h1>
                There was an error while signing out...
            </h1>
        )
    });
}



export default Signout;
